<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t('agreementAttachments.addNewFile') }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="isFormValid">
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field :label="$t('agreementAttachments.fileName')" v-model="filename" :rules="[...isNotEmptyValidation]"/>
            </v-col>
            <v-col cols="12" md="12">
              <v-file-input :label="$t('agreementAttachments.addFile')" v-model="fileAttachment" :rules="[...fileValidators]" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-3 pr-3">
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onSaveDocument" :loading="isBtnLoading" :disabled="!isFormValid">
        {{ $t('general.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { RESPONSE_LEVEL } from '@/variables'
import {
  createErrorNotification,
  createWarningNotification
} from '@/lib/unnotificationsqueue'

export default {
  name: 'AgreementAttachmentsModal',

  data () {
    return {
      isFormValid: false,
      filename: '',
      fileAttachment: null,
      isNotEmptyValidation: [
        v => !!v || this.$t('agreementAttachments.validationErrors.noName')
      ],
      fileValidators: [
        v => (v !== null) || this.$t('agreementAttachments.validationErrors.noFile')
      ],
      isBtnLoading: false
    }
  },

  computed: {
    ...mapState('agreements', ['currentItem'])
  },

  methods: {
    ...mapActions('agreementAttachments', ['postFormItem']),
    ...mapActions({ addNotification: 'addNotification' }),

    async onSaveDocument () {
      try {
        this.isBtnLoading = true
        if (this.$refs.form.validate()) {
          const formData = new FormData()
          formData.append('file', this.fileAttachment)
          formData.append('name', this.filename)
          formData.append('referenced_item', this.currentItem.id)
          await this.postFormItem(formData)
          this.onCleanForm()
          this.$emit('save')
        }
      } catch (error) {
        let errorResponse = error.response && error.response.data
        if (errorResponse && errorResponse.level === RESPONSE_LEVEL.WARNING) {
          this.addNotification(createWarningNotification(errorResponse.message))
        } else if (errorResponse.level === RESPONSE_LEVEL.ERROR) {
          this.addNotification(createErrorNotification(errorResponse.message))
        } else {
          this.addNotification(createErrorNotification(this.$t('agreementAttachments.saveFileError')))
        }
      } finally {
        this.isBtnLoading = false
      }
    },
    onCleanForm () {
      this.filename = ''
      this.fileAttachment = null
      this.$refs.form.resetValidation()
    }
  }
}
</script>
