<template>
  <v-row>
    <v-col cols="6" align-self="start">
      <DateRangePickerInField
        :label="$t('agreements.agreedDates')"
        :start.sync="startDate"
        :end.sync="endDate"
        :validateDate="true"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        :value="value.effective_dates"
        :label="$t('agreements.effectiveDates')"
        disabled
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import Lget from 'lodash/get'

import Lset from 'lodash/set'
import { Mutex } from 'async-mutex'

import DateRangePickerInField from '@/apps/core/components/forms/DateRangePickerInField/DateRangePickerInField.vue'

export default {
  name: 'AgreementDatesRangeView',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      lower: 'lower',
      upper: 'upper',
      DATE_FORMAT_UPDATE: 'YYYY-MM-DD',
      dateName: 'agreed_dates',
      mutex: new Mutex()
    }
  },
  components: { DateRangePickerInField },
  computed: {
    currentDate () {
      return { 'bounds': '[)', 'lower': '', 'upper': '' }
    },
    startDate: {
      get: function () {
        return this.getDateByCurrentItem(this.dateName, this.lower)
      },
      set: async function (value) {
        // Mutex is use to prevent concurrent access to date.
        await this.mutex.runExclusive(async () => {
          const date = this.setDateByCurrentItem(this.dateName, this.lower, value)
          let emitItem = { ...this.value }
          emitItem[this.dateName] = JSON.stringify(date)
          this.$emit('input', emitItem)
        })
      }
    },
    endDate: {
      get: function () {
        return this.getDateByCurrentItem(this.dateName, this.upper)
      },
      set: async function (value) {
        // Mutex is use to prevent concurrent access to date.
        await this.mutex.runExclusive(async () => {
          const date = this.setDateByCurrentItem(this.dateName, this.upper, value)
          if (value === '') return
          let emitItem = { ...this.value }
          emitItem[this.dateName] = JSON.stringify(date)
          this.$emit('input', emitItem)
        })
      }
    }
  },
  methods: {
    Lget: Lget,
    getDateByCurrentItem (keyCurrentItem, key) {
      const date = this.value[keyCurrentItem] ? JSON.parse(this.value[keyCurrentItem]) : this.currentDate
      return Lget(date, key)
    },
    setDateByCurrentItem (keyCurrentItem, key, value) {
      const date = this.value[keyCurrentItem] ? JSON.parse(this.value[keyCurrentItem]) : this.currentDate
      var updateDate = date
      if (key === this.lower) {
        updateDate = Lset(date, key, value)
        updateDate = Lset(updateDate, this.upper, '')
      } else if (key === this.upper) {
        var lowerDate = Lget(date, this.lower)

        if (lowerDate > value) {
          updateDate = Lset(updateDate, this.lower, value)
          updateDate = Lset(updateDate, this.upper, lowerDate)
        } else {
          updateDate = Lset(updateDate, this.lower, lowerDate)
          updateDate = Lset(updateDate, this.upper, value)
        }
      }
      return updateDate
    },
    updateDate (date) {
      let argsDate = { ...this.value }
      argsDate[this.mainDateName] = JSON.stringify(date)
      return argsDate
    }
  }
}
</script>
