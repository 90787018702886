import { mapActions, mapState } from 'vuex'

import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import CustomViewDialog from '@/apps/core/components/CustomViewDialog/CustomViewDialog.vue'
import HierarchiesFiltersSelectorWrapper from '@/apps/core/components/HierarchiesFiltersSelectorWrapper/HierarchiesFiltersSelectorWrapper.vue'
import { propToProps, flattenFormProps } from '@/apps/core/helpers/utils'
import apiClient from '@/lib/unlogin/store/apiclient'
import { createWarningNotification } from '@/lib/unnotificationsqueue'

export default {
  name: 'AgreementLineTable',
  components: {
    CrudTable,
    CustomViewDialog
  },
  data () {
    return {
      loading: false,
      agreementId: this.$route.params.id,
      storeModule: 'agreementLines',
      showAgreementLineDetailModal: false,
      currentAgreementLine: {},
      options: {},
      customComponentCatalog: {
        HierarchiesFiltersSelectorWrapper
      }
    }
  },
  computed: {
    ...mapState('agreements', ['currentItem']),
    ...mapState({
      crudTableConfig: function (state) {
        return state[this.storeModule].crudTableConfig
      }
    }),
    ...mapActions('agreementLines', ['createOrUpdateAgreementLine']),
    ...mapActions({ addNotification: 'addNotification' }),
    customViewActions () {
      return [{ label: this.$t('agreements.applyConditions'), icon: 'fa-save', color: 'primary', event: 'save', validate: true }]
    },
    includedHierarchiesFilters () {
      return this.$store.getters['hierarchiesFilterSelectorStore/includedFilters']
    }
  },
  watch: {
    showAgreementLineDetailModal (val) {
      if (!val) this.currentAgreementLine = {}
    }
  },
  methods: {
    addAgreementLines () {
      if (this.currentItem.rate && this.includedHierarchiesFilters.length) {
        this.showAgreementLineDetailModal = true
        if (this.$store.state.hierarchiesFiltersListProduct) this.$store.commit('hierarchiesFiltersListProduct/clearItems')
      } else {
        this.$store.dispatch('addNotification', createWarningNotification(this.$t('general.agreementLineCannotBeCreated')))
      }
    },
    async editAgreementLine ({ value, header, item }) {
      try {
        await this.onModifyAgreementLine(header, value, item)
      } catch (error) {
        console.error(error)
      }
    },
    async onModifyAgreementLine (header, value, item) {
      try {
        await apiClient.patch(`/agreement-lines/${item.id}/`, flattenFormProps({ [header]: value }))
        await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async saveForm () {
      this.loading = true
      try {
        const form = new FormData()
        const productList = this.$store.state.hierarchiesFiltersListProduct.items.map(product => product.id)
        form.append('agreement_id', this.agreementId)
        form.append('product_id_list', JSON.stringify(productList))
        form.append('agreement_line_attributes', JSON.stringify(propToProps(this.currentAgreementLine)))
        await this.$store.dispatch(`${this.storeModule}/createOrUpdateAgreementLine`, form)
        await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
        this.showAgreementLineDetailModal = false
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
