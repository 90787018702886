import { mapActions, mapState } from 'vuex'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import CustomViewDialog from '@/apps/core/components/CustomViewDialog/CustomViewDialog.vue'
import { BASE_URL } from '@/variables'

export default {
  name: 'ContributionsTable',
  components: {
    CrudTable,
    CustomViewDialog
  },
  props: {
    'title': {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      agreementId: this.$route.params.id,
      storeModule: 'contributions',
      options: {
        context: {
          serverUrl: BASE_URL,
          reload: true
        }
      },
      currentContributionDetail: {},
      showContributionDetailModal: false,
      editingMode: false
    }
  },
  computed: {
    ...mapState('contributions', ['currentItem']),
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    }),
    ...mapActions('contributions', ['getItem', 'postItem']),

    getLinkAttributes () {
      const linkAttributes = {}
      let value = this.crudTableConfig.headers?.length > 0 ? this.crudTableConfig.headers[0].value : ''
      linkAttributes['path'] = `item.${value}`
      linkAttributes['name'] = value
      return linkAttributes
    },
    customViewActions () {
      return [{ label: this.$t('general.save'), icon: 'fa-save', color: 'primary', event: 'save', validate: true }]
    }
  },
  beforeDestroy () {
    this.$store.commit(`${this.storeModule}/clearItems`)
  },
  watch: {
    showContributionDetailModal (val) {
      if (!val) {
        this.$store.commit(`${this.storeModule}/resetCurrent`)
        if (this.editingMode) this.editingMode = false
      }
    }
  },
  methods: {
    onCreateContribution () {
      this.showContributionDetailModal = true
    },
    async onEditContribution (contributionId) {
      this.showContributionDetailModal = true
      this.editingMode = true
      try {
        await this.$store.dispatch(`${this.storeModule}/getContribution`, contributionId)
        this.currentContributionDetail = { ...this.currentItem }
      } catch (e) {
        console.error(e)
      }
    },
    async saveForm () {
      this.loading = true

      try {
        if (this.editingMode) {
          await this.$store.dispatch(`${this.storeModule}/putFormItem`, this.currentContributionDetail)
        } else {
          const newItem = { ...this.currentContributionDetail, agreement: this.agreementId }
          await this.$store.dispatch(`${this.storeModule}/postFormItem`, newItem)
        }
        this.currentContributionDetail = {}
        this.showContributionDetailModal = false
        await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
        await this.$store.dispatch(`agreementLines/reloadItemList`)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async onDeleteContribution () {
      await Promise.all([
        this.$store.dispatch(`agreementLines/reloadItemList`),
        this.$store.dispatch(`endorsements/reloadItemList`)
      ])
    }
  }
}
