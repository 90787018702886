import { mapActions, mapState } from 'vuex'
import { isEqual } from 'lodash'

import { createNotification } from '@/lib/unnotificationsqueue'

import { BASE_URL, RESPONSE_LEVEL } from '@/variables'

import Layout from '@/apps/core/components/Layout'
import CustomLayout from '@/apps/core/components/CustomLayout'
import ConfirmDialog from '@/apps/core/components/ConfirmDialogAlt.vue'
import HierarchiesFiltersSelectorWrapper from '@/apps/core/components/HierarchiesFiltersSelectorWrapper/HierarchiesFiltersSelectorWrapper.vue'

import sections from '@/apps/dido/views/defaultNavigationDrawerSections'

import AgreementDatesRangeView from '@/apps/juno2/components/AgreementDatesRangeView'
import AgreementLineTable from '@/apps/juno2/components/tables/AgreementLineTable/AgreementLineTable'
import ContributionsTable from '@/apps/juno2/components/tables/ContributionsTable/ContributionsTable'
import EndorsementsTable from '@/apps/juno2/components/tables/EndorsementsTable/EndorsementsTable'
import AgreementHistoryTable from '@/apps/juno2/components/tables/AgreementHistoryTable/AgreementHistoryTable'
import AgreementAttachmentsTable from '@/apps/juno2/components/tables/AgreementAttachmentsTable'

export default {
  components: { CustomLayout, Layout, ConfirmDialog },
  data () {
    return {
      actionSelected: {},
      loading: false,
      isReloadCustomViewChoices: false,
      firstIteration: true,
      sections: sections,
      agreementId: this.$route.params.id,
      customComponentCatalog: {
        AgreementLineTable,
        ContributionsTable,
        EndorsementsTable,
        AgreementDatesRangeView,
        HierarchiesFiltersSelectorWrapper,
        AgreementHistoryTable,
        AgreementAttachmentsTable
      },
      options: {
        context: {
          agreementId: this.getAgreementId,
          serverUrl: BASE_URL
        }
      },
      staticOldItem: null,
      countChanges: 0,
      isSaving: false
    }
  },
  computed: {
    ...mapState('agreements', ['currentItem']),
    updatedCurrentItem () {
      return this.currentItem
    },
    showForm () {
      return this.currentItem.id > 0
    },
    title () {
      return this.showForm
        ? this.$t('agreements.sectionName', { name: this.currentItem.name || '' })
        : this.$t('agreements.agreementDetailLoading')
    },
    formTitle () {
      return this.currentItem.name || this.$t('general.newPrimary')
    },
    customForm () {
      return this.$refs.custom && this.$refs.custom.$refs.form
    },
    customActions () {
      return [
        {
          label: this.$t('general.delete'),
          method: this.deleteForm,
          confirm: true,
          confirmOptions: {
            titleDialog: this.$t('agreements.detailDeleteMessage'),
            confirmText: this.$t('dialogs.confirmOption'),
            confirmColor: 'red'
          },
          icon: 'fa-times',
          color: 'red',
          dark: true,
          floatEnd: true
        },
        {
          label: this.$t('customActions.saveAndContinue'),
          method: this.saveForm,
          args: { redirect: false },
          validate: true,
          icon: 'fa-save',
          color: 'primary'
        },
        {
          label: this.$t('general.save'),
          method: this.saveForm,
          args: { redirect: true },
          validate: true,
          icon: 'fa-save',
          color: 'primary'
        }
      ]
    },
    agreementHasChanged () {
      let changed = false
      if (this.currentItem !== {} && this.staticOldItem) {
        changed = !isEqual(this.currentItem, this.staticOldItem)
      }
      return changed
    }
  },
  watch: {
    currentItem: {
      deep: true,
      handler () {
        if (this.currentItem && this.countChanges < 3) {
          this.staticOldItem = this.currentItem
          this.countChanges++
        }
      }
    }
  },
  created () {
    this.clearCurrentItem()
    this.getAgreement(this.agreementId)
  },
  beforeDestroy () {
    this.dismissNotifications()
  },
  methods: {
    ...mapActions('agreements', [
      'updateCurrentItem',
      'getAgreement',
      'updateAgreement',
      'deleteAgreement',
      'clearCurrentItem'
    ]),
    ...mapActions({ addNotification: 'addNotification', dismissNotifications: 'dismissNotifications' }),
    getAgreementId () {
      return this.$route.params.id || -1
    },
    reloadCustomViewChoices () {
      this.isReloadCustomViewChoices = true
      this.$nextTick().then(() => {
        this.isReloadCustomViewChoices = false
      })
    },
    async saveForm (config = {}) {
      this.loading = true
      this.isSaving = true
      try {
        const response = await this.updateAgreement(this.agreementId)
        if (response) {
          if (config.redirect) {
            await this.$router.push({ name: 'AgreementList' })
          } else {
            location.reload()
          }
        }
      } finally {
        this.loading = false
        this.isSaving = false
      }
    },
    async deleteForm () {
      this.loading = true
      try {
        const response = await this.deleteAgreement(this.agreementId)
        if (response) await this.$router.push({ name: 'AgreementList' })
      } finally {
        this.loading = false
      }
    },
    updateItem (items) {
      this.updateCurrentItem({ ...this.currentItem, ...items })
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (this.agreementHasChanged && !this.isSaving) {
      if (await this.$refs.confirm.open(this.$t('general.warning'), this.$t('dialogs.pendingChanges'))) {
        if (this.customForm.validate()) {
          const res = await this.updateAgreement(this.agreementId)
          if (res.level === RESPONSE_LEVEL.SUCCESS) {
            next()
          } else {
            next(false)
          }
        } else {
          next(false)
          this.addNotification(createNotification(this.$t('dialogs.errorFormData'), RESPONSE_LEVEL.ERROR))
        }
      } else {
        next()
      }
    } else {
      next()
    }
  }
}
