<template>
  <div>
      <CrudTable
        v-bind="crudTableConfig"
        :storeModule="storeModule"
        :showAddItemsCustom="true"
        :parentKey="parentKey"
        :parentValue="String(parentValue)"
        :itemsPerPage="[10, 25, 50, 75]"
        @addItemsEvent="showForm = true"
    >
      <template v-slot:item.creation_datetime="{ item }">
        <span>{{ item.creation_datetime | detectAndFormatDate }}</span>
      </template>

      <template v-slot:item.file="{ item }">
        <v-tooltip color="black" dark left>
          <template v-slot:activator="{ on }">
            <v-btn v-show="item.file" :href="item.file" color="primary" target="_blank" fab small dark v-on="on" class="my-3" >
              <v-icon>fas fa-file-download</v-icon>
            </v-btn>
          </template>
          <span>{{ valueToShow(item.file) }}</span>
        </v-tooltip>

      </template>
    </CrudTable>
    <v-dialog v-model="showForm" max-width="50%">
      <AgreementAttachmentsModal @save="onItemCreated"/>
    </v-dialog>
  </div>
</template>

<script>
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import AgreementAttachmentsModal from '../AgreementAttachmentsModal'
import { createSuccessNotification } from '@/lib/unnotificationsqueue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AgreementAttachmentsTable',
  components: { CrudTable, AgreementAttachmentsModal },
  data () {
    return {
      parentKey: 'referenced_item',
      parentValue: this.$route.params.id,
      storeModule: 'agreementAttachments',
      showForm: false
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) {
        return state[this.storeModule].crudTableConfig
      }
    })
  },
  created () {
    if (!(this.$store && this.$store.state && this.$store.state[this.storeModule])) {
      this.$store.registerModule(this.storeModule, makeCrudModule(apiClient, 'AgreementAttachments', '/agreement-attachments/'))
    }
  },
  methods: {
    ...mapActions({ addNotification: 'addNotification' }),
    ...mapActions('agreementAttachments', [
      'getItem',
      'getDocument',
      'reloadItemList'
    ]),
    onItemCreated () {
      this.addNotification(createSuccessNotification(this.$t('agreementAttachments.saveFileSuccess')))
      this.reloadItemList()
      this.showForm = false
    },
    valueToShow (file) {
      return file && file.split('/').slice(-1)[0]
    }
  }
}
</script>
